import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import { subscribe } from 'helpers/global-events';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import 'styles/base/subsys/management-toolbar/_admin-panel-global.scss';

const classes = new Bem('adminPanelGlobal');

/**
 * Administrative tool panel. Components can register/unregister on this panel with global events.
 * Events: registerAdminComponent, unregisterAdminComponent
 *
 * registerAdminComponent params:
 *   name: unique name of component
 *   component - component
 *   props - component props
 *
 * unregisterAdminComponent params: string with component name
 *
 */
export default class Panel extends PureComponent {
  static propTypes = {
    className: PropTypes.string
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.components = {};
    subscribe('registerAdminComponent', this.registerComponent);
    subscribe('unregisterAdminComponent', this.unregisterComponent);
  }

  registerComponent = (params) => {
    const { name, ...others } = params;
    this.components[name] = others;
    this.forceUpdate();
  };

  unregisterComponent = (name) => {
    delete this.components[name];
    this.forceUpdate();
  };

  render() {
    if (isEmpty(this.components)) return null;

    const components = map(this.components, (item, key) => {
      if (!item) return null;

      return (
        <div key={key} {...classes('item')}>
          <item.component {...item.props} />
        </div>
      );
    });

    return (
      <div {...classes({ extra: this.props.className })}>
        <div {...classes('content')} children={components} />
      </div>
    );
  }
}
